import React from 'react'
import "../components/OurVision.css"

function OurVision() {
  return (
    <div className='ourvision'>
      <di className='ourvision_Content'>
        <div className='ourvision_Header'>
            <h1>Our <span className='ourvision_Header_Yellow'>Vision</span></h1>
            <div className='ourvision_Bar'>

            </div>
        </div>
        <div className='ourvision_Texts'>
            <p>Our vision at Senary Holdings is to be the gold standard in private equity real estate, revolutionizing the industry with our commitment to integrity, innovation, and excellence. </p>
            <h5>We aim to redefine the landscape of investment by fostering sustainable wealth creation and by being a catalyst for growth that benefits not just our investors but also the communities we serve. Through our dedicated efforts, we aspire to build a future where prosperity is a legacy passed down through generations, anchored in the trust and success we achieve together with our clients and partners.</h5>
        </div>
        <div className='ourvision_Card'>
            
        </div>
      </di>
    </div>
  )
}

export default OurVision
