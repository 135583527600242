import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import OurVision from './components/OurVision';
import OurMission from './components/OurMission';
import Footer from './components/Footer';
import Services from './components/Services';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <About/>
      <OurVision/>
      <OurMission/>
      <Services/>
      <Footer/>
    </div>
  );
}

export default App;
