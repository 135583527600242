import React from 'react'
import "../components/About.css"

function About() {
  return (
    <div className='about'>
        <div className='about_Content'>
            <div className='about_Image'>
            <img src="images/about.png" alt="favicon"/>
            </div>
            <div className='about_Text'>
                <h1>About <span className='about_Text_Yellow'>Us</span></h1>
                <p>Based in New York City, Senary Holdings is a private equity firm that specializes in a full range of real estate investment services, including acquisitions, asset and property management and new development.</p>
                <h5>Our reputation as industry leaders is built on a solid foundation of success and the strong, lasting bonds we’ve established with our partners, clients, and colleagues.
                Our team is committed to fostering a vibrant and effective workplace where everyone reflects an “all hands on deck” mentality.</h5>
            </div>
        </div>
      
    </div>
  )
}

export default About
