import React from 'react';
import "../components/OurMission.css";

function OurMission() {
  return (
    <div className='ourmission'>
      <div className='ourmission_Content'>
        <div className='ourmission_Header'>
            <h1>Our <span className='ourmission_Header_Yellow'>Mission</span></h1>
            <div className='ourmission_Bar'></div>
        </div>
        <div className='ourmission_Texts'>
            <p>Our mission is to harness our unparalleled reputation and depth of experience in the real estate domain,</p>
            <h5>upholding the highest levels of integrity, efficiency, and ethical business practices. We are committed to delivering favorable returns for our investors and constructing a legacy of long-term generational wealth.
            </h5>
        </div>
        <div className='ourmission_Card'></div>
      </div>
    </div>
  );
}

export default OurMission;
