import React from 'react'
import "../components/Footer.css"

function Footer() {
  return (
    <div className='footer'>
        <div className='footer_Content'>
            <div className='footer_Top'>
                <img src="images/logotype.svg" alt="logo"/>
                <div className='footer_Contact'>
                    <h5>CONTACT</h5>
                    <p>Shlomo Althkefati, COO
                    </p>
                    <p className='italic_Test'>Shlomo.Althkefati@senaryholdings.com</p>
                </div>
            </div>
            <div className='footer_Bottom'>
                <p>©2024 - Senary Holdings</p>
                <p>All rights reserved</p>
            </div>
        </div>
      
    </div>
  )
}

export default Footer
