import React from 'react'
import "../components/Hero.css"



function Hero() {
  return (
    <div className='hero'>
        <div className='hero_Content'>
          <div className='hero_Bar'>
            
          </div>
            <p>DISCOVER EXQUISITE LUXURY</p>
            <h1>Explore the World's Most</h1>
            <h2>Prestigious Properties</h2>
        </div>
      
    </div>
  )
}

export default Hero
