import React from 'react'
import "../components/Services.css"

function Services() {
  return (
    <div className='services'>
        <div className='services_Content'>
            <h1>Our <span className='services_Content_Yellow'>Services</span></h1>
            <div className='services_Cards'>
                <div className='services_Card'>
                    <h1>Real Estate Acquisitions and Investments</h1>
                    <p><span className='checkmark'></span> Sourcing</p>
                    <p><span className='checkmark'></span> Underwriting</p>
                    <p><span className='checkmark'></span> Financing</p>
                </div>
                <div className='services_Card'>
                    <h1>Asset and Property Management</h1>
                    <p><span className='checkmark'></span> Operational Oversight</p>
                    <p><span className='checkmark'></span> Financial Reporting</p>
                    <p><span className='checkmark'></span> Design and Marketing</p>
                    <p><span className='checkmark'></span> Construction Management</p>
                    <p><span className='checkmark'></span> Tenant Relations</p>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default Services
