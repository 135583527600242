import React from 'react';
import '../components/Navbar.css';

function Navbar() {
  return (
    <div className='navbar'>
      <div className='navbar_Content'>
        <div className='navbar_Logo'>
            <img src="images/logotype.svg" alt="logo"/>
          </div>
      </div>
    </div>
  );
}

export default Navbar;